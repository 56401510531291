import React, {useEffect, useState} from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {isSessionSuccess} from "../../StateManagement/sessionSlice";
// import Logo from "../../images/header_logo.png";
import Logo from "../../images/header_logo.svg";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Hamburger from "../users/Hamburger";
import useReactPath from "./useReactPath";


const NavBar = function NavBar() {
    const classes = useStyles();
    const history = useHistory();
    const path = useReactPath();
    const menu = path.split("/")[1];
    const mainMenuList = ["materi", "pr", "bank-soal", "tryout"];
    const [showHamburger, setShowHamburger] = useState(false);
    const isLoggedIn = useSelector(isSessionSuccess);

    const gotoBeranda = function() {
        history.push("/");
    }

    useEffect(() => {
        if (mainMenuList.includes(menu)) {
            setShowHamburger(true);
        }

        return () => {
            setShowHamburger(false);
        };
    }, [menu, mainMenuList]);

    return (
        <Container className={classes.container}>
            <Grid item sm={4} className={classes.logo}>
                <img src={Logo} alt="logo" onClick={gotoBeranda}/>
            </Grid>
            {showHamburger ? <Hamburger/> : <NavigationMenu/>}
        </Container>
    );
}

function NavigationMenu() {
    const classes = useStyles();
    const [openMobileNav, setOpenMobileNav] = useState(false);

    function handleOpenMobileNav() {
        setOpenMobileNav(true);
    }

    function handleCloseMobileNav() {
        setOpenMobileNav(false);
    }

    return (
        <React.Fragment>
            <Hidden smUp>
                <Grid item sm={8} className={classes.hamburger}>
                    <img
                        src="/images/hamburger.png"
                        alt="hamburger"
                        onClick={handleOpenMobileNav}
                    />
                    <MobileNav
                        openNav={openMobileNav}
                        handleNavClose={handleCloseMobileNav}
                    />
                </Grid>
            </Hidden>
            <Hidden xsDown>
                <Grid item sm={8} className={classes.navigasi}>
                    <ul>
                        <li>
                            <Link to="/product">Program Belajar</Link>
                        </li>
                        <li>
                            <Link to="/product/event">Event</Link>
                        </li>
                        <li>
                            <Link to="/welcome-tryout">Try Out</Link>
                        </li>
                        <li>
                            <Link to="/toko">Toko Tubel</Link>
                        </li>
                        <li>
                            <Link to="/tentang-kami">Tentang Kami</Link>
                        </li>
                    </ul>
                </Grid>
            </Hidden>
        </React.Fragment>
    );
}

function MobileNav({openNav, handleNavClose}) {
    const classes = useStyles();

    return (
        <Drawer open={openNav} onClose={handleNavClose}>
            <div className={classes.containerMobileNav}>
                <ul>
                    <li>
                        <Link to="/product" onClick={handleNavClose}>
                            Program Belajar
                        </Link>
                    </li>
                    <li>
                        <Link to="/product/event" onClick={handleNavClose}>
                            Event
                        </Link>
                    </li>
                    <li>
                        {/* <Link to="/produk/tryout">Try Out</Link> */}
                        <Link to="/welcome-tryout" onClick={handleNavClose}>
                            Try Out
                        </Link>
                    </li>
                    <li>
                        <Link to="/toko" onClick={handleNavClose}>
                            Toko Tubel
                        </Link>
                    </li>
                    <li>
                        <Link to="/tentang-kami" onClick={handleNavClose}>
                            Tentang Kami
                        </Link>
                    </li>
                </ul>
            </div>
        </Drawer>
    );
}

const useStyles = makeStyles({
    container: {
        height: 80,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "@media (max-width:425px)": {
            height: 60,
        },
        "@media (min-width:1440px)": {
            fontSize: "1.1em",
        },
    },
    navigasi: {
        display: "flex",
        justifyContent: "flex-start",
        "& ul": {
            listStyleType: "none",
            margin: 0,
            padding: 0,
        },
        "& ul > li": {
            display: "inline",
            marginRight: 25,
            "&:hover": {
                borderBottom: "2px solid",
                paddingBottom: 3,
            },
            cursor: "pointer",
            "@media (max-width:450px)": {
                visibility: "hidden",
            },
            "& a": {
                color: "#fff",
                textDecoration: "none",
            },
        },
        "@media (max-width:600px)": {
            visibility: "hidden",
        },
    },
    logo: {
        display: "flex",
        justifyContent: "flex-start",
        "& img": {
            width: "90%",
            height: "90%",
            cursor: "pointer",
            "@media (min-width:425px)": {
                width: "80%",
                height: "80%",
            },
            "@media (min-width:768px)": {
                width: "70%",
                height: "70%",
            },
            "@media (min-width:1024px)": {
                width: "50%",
                height: "50%",
            },
            "@media (min-width:1200px)": {
                width: "35%",
                height: "35%",
            },
        },
    },
    hamburger: {
        width: "70%",
        display: "flex",
        justifyContent: "flex-end",
        "& img": {
            cursor: "pointer",
        },
        zIndex: 2,
    },
    containerMobileNav: {
        width: 260,
        marginTop: 50,
        "& ul": {
            listStyleType: "none",
            padingLeft: 20,
            fontSize: "1.3em",
            "& li": {
                marginBottom: 20,
            },
            "& li > a": {
                color: "#000",
                textDecoration: "none",
            },
        },
    },
});



export default NavBar;
