import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: window.innerHeight - 475,
    },
    link: {
        textDecoration: 'underline',
        cursor: 'pointer'
    }
})

const LoginFirst = () => {
    const history = useHistory();
    const classes = useStyles();

    return (
        <div className={classes.container}>
            Session expired, please &nbsp; <a className={classes.link} onClick={() => history.push("/login")}> Log
            in </a>.
        </div>
    )

}

export default LoginFirst;
