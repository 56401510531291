import {configureStore} from '@reduxjs/toolkit'

import sessionReducer from './sessionSlice'
import infoReducer from './infoSlice'

export default configureStore({
    reducer: {
        session: sessionReducer,
        info: infoReducer
    }
})