import React from "react";
import {Link} from "react-router-dom";
import {Dialog, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import MenuBookIcon from "@material-ui/icons/MenuBook";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AssignmentIcon from "@material-ui/icons/Assignment";

import COLOR from "../../Style";

const useStyles = makeStyles({
  hamburger: {
    width: "70%",
    display: "flex",
    justifyContent: "flex-end",
    // backgroundColor: 'red',
    color: "#fff",
    "& img": {
      cursor: "pointer",
      marginLeft: 10,
    },
    zIndex: 2,
  },
  logo: {
    width: "40px",
    height: "40px",
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  label: {
    fontSize: "0.7em",
    fontWeight: "600",
    textAlign: "center",
    width: "40px",
  },
  dialogtitle: {
    textAlign: "center",
    fontWeight: "600",
  },
  menu: {
    width: "100%",
  },
  menuIcon: {
    textAlign: "center",
    "& a": {
      color: COLOR.coklatFooter,
    },
  },
  menuLabel: {
    textAlign: "center",
    "& a": {
      textAlign: "center",
      fontWeight: "600",
      marginTop: "10px",
      color: COLOR.coklatFooter,
      textDecoration: "none",
    },
  },
  menuItem: {
    marginBottom: "20px",
    width: "150px",
    "@media (max-width:500px)": {
      width: "60px",
      // border: '1px solid red'
    },
  },
  dialogContent: {
    padding: "10px",
  },
  menuButton: {
    cursor: "pointer",
    fontWeight: "bold",
    backgroundColor: COLOR.coklatMuda,
    padding: 6,
    borderRadius: 5,
    color: COLOR.coklatTua,
  },
});

const Hamburger = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Grid item sm={8} className={classes.hamburger}>
        <div className={classes.menuButton} onClick={handleClick}>
          MENU
        </div>
        {/* <img src="/images/hamburger.png" alt="hamburger" onClick={handleClick}/> */}
      </Grid>
      <Dialog
        open={open}
        // fullWidth={true}
        onClose={handleClose}
        aria-labelledby="menu-dialog"
        maxWidth="md"
      >
        <DialogTitle id="menu-dialog" className={classes.dialogtitle}>
          Menu
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Menu close={handleClose} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

const Menu = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.menu}
      direction="row"
      justify="space-evenly"
      alignItems="center"
    >
      <Grid
        item
        onClick={props.close}
        container
        md={2}
        xs={6}
        sm={3}
        className={classes.menuItem}
      >
        <Grid item xs={12} className={classes.menuIcon}>
          <Link to="/materi/tpa">
            <MenuBookIcon fontSize="large" />
          </Link>
        </Grid>
        <Grid item xs={12} className={classes.menuLabel}>
          <Link to="/materi/tpa">TPA</Link>
        </Grid>
      </Grid>
      <Grid
        item
        onClick={props.close}
        container
        md={2}
        xs={6}
        sm={3}
        className={classes.menuItem}
      >
        <Grid item xs={12} className={classes.menuIcon}>
          <Link to="/materi/tbi">
            <MenuBookIcon fontSize="large" />
          </Link>
        </Grid>
        <Grid item xs={12} className={classes.menuLabel}>
          <Link to="/materi/tbi">TBI</Link>
        </Grid>
      </Grid>
      <Grid
        item
        onClick={props.close}
        container
        md={2}
        xs={6}
        sm={3}
        className={classes.menuItem}
      >
        <Grid item xs={12} className={classes.menuIcon}>
          <Link to="/pr">
            <NotificationsActiveIcon fontSize="large" />
          </Link>
        </Grid>
        <Grid item xs={12} className={classes.menuLabel}>
          <Link to="/pr">PR</Link>
        </Grid>
      </Grid>
      <Grid
        item
        onClick={props.close}
        container
        md={2}
        xs={6}
        sm={3}
        className={classes.menuItem}
      >
        <Grid item xs={12} className={classes.menuIcon}>
          <Link to="/mini-game">
            <AssignmentIcon fontSize="large" />
          </Link>
        </Grid>
        <Grid item xs={12} className={classes.menuLabel}>
          <Link to="/mini-game">Mini Games</Link>
        </Grid>
      </Grid>
      <Grid
        item
        onClick={props.close}
        container
        md={2}
        xs={6}
        sm={3}
        className={classes.menuItem}
      >
        <Grid item xs={12} className={classes.menuIcon}>
          <Link to="/welcome-tryout">
            <EmojiEventsIcon fontSize="large" />
          </Link>
        </Grid>
        <Grid item xs={12} className={classes.menuLabel}>
          <Link to="/welcome-tryout">Tryout</Link>
        </Grid>
      </Grid>
      <Grid
        item
        onClick={props.close}
        container
        md={2}
        xs={6}
        sm={3}
        className={classes.menuItem}
      >
        <Grid item xs={12} className={classes.menuIcon}>
          <Link to="/toko">
            <ShoppingCartIcon fontSize="large" />
          </Link>
        </Grid>
        <Grid item xs={12} className={classes.menuLabel}>
          <Link to="/toko">Toko Tubel</Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(Hamburger);
