import React from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import COLOR from "../../Style";
import Logo from "./../../images/footer_logo.svg";

const useStyles = makeStyles({
  container: {
    backgroundColor: COLOR.kuning,
    width: "100%",
    paddingTop: 25,
    marginTop: 100,
  },
  content: {
    height: 180,
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width:425px)": {
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: 30,
      height: "100%",
    },
  },
  mediasosial: {
    display: "flex",
    flexDirection: "column",
    width: 200,
    "@media (max-width:425px)": {
      justifyContent: "center",
      flexDirection: "row",
      width: "100%",
    },
  },
  meedsosItem: {
    display: "flex",
    alignItems: "center",
    height: 30,
    marginBottom: 15,
    cursor: "pointer",
    "& img": {
      height: 30,
      marginRight: 10,
    },
    fontSize: 14,
    color: COLOR.coklatTua,
    "@media (max-width:425px)": {
      marginRight: 10,
    },
  },
  logo: {
    "& img": {
      height: 45,
      cursor: "pointer",
      "@media (max-width:425px)": {
        height: 50,
      },
    },
  },
  produk: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 30,
    "@media (min-width:425px)": {
      marginTop: 0,
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-evenly",
    },
  },
  produkItem: {
    textAlign: "center",
    "& span": {
      margin: 5,
      "@media (min-width:425px)": {
        margin: 0,
      },
    },
    "& a": {
      color: COLOR.coklatTua,
      textDecoration: "none",
    },
    "@media (min-width:425px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  produkTitle: {
    textAlign: "center",
    fontWeight: 700,
    color: COLOR.coklatTua,
    marginTop: 10,
    "@media (min-width:425px)": {
      marginTop: 0,
      textAlign: "left",
      marginBottom: 10,
    },
  },
  linkNitip: {
    color: COLOR.kuning,
  },
});

const Footer = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const date = new Date();
  const year = date.getFullYear();

  function gotoBeranda() {
    history.push("/");
  }

  function linkNewTab(url) {
    window.open(url, "_blank");
  }

  return (
    <div className={classes.container} id="footer">
      <Container maxWidth="lg" className={classes.content}>
        <div className={classes.logo}>
          <img src={Logo} onClick={gotoBeranda} />
        </div>
        <div className={classes.produk}>
          <div>
            <div className={classes.produkTitle}>PRODUK</div>
            <div className={classes.produkItem}>
              <span>
                <Link to="/product">Program Kelas</Link>
              </span>
              <span>
                <Link to="/product/event">Events</Link>
              </span>
              {/*<span>*/}
              {/*    <Link to="/welcome-tryout">Try Out Nasional</Link>*/}
              {/*</span>*/}
            </div>
          </div>
          <div>
            <div className={classes.produkTitle}>TOKO TUBEL</div>
            <div className={classes.produkItem}>
              <span>Modul</span>
            </div>
          </div>
        </div>
        <div className={classes.mediasosial}>
          <div
            className={classes.meedsosItem}
            onClick={() => {
              linkNewTab("https://instagram.com/persiapantubel");
            }}
          >
            <img alt="instagram" src="/images/instagram.svg" />
            <span>Persiapantubel</span>
          </div>
          <div
            className={classes.meedsosItem}
            onClick={() => {
              linkNewTab(
                "https://api.whatsapp.com/send?phone=6281578042872&text=Halo%2C+Tubelmin%2C+ada+yang+ingin+saya+tanyakan+tentang+Kelas+Persiapan+SPMB+Tubel.+tolong+dibantu%2C+ya.%0D%0ATerimakasih"
              );
            }}
          >
            <img alt="whatsapp" src="/images/whatsapp.svg" />
            <span>Whatsapp Admin</span>
          </div>
        </div>
        <a
          className={classes.linkNitip}
          href="https://loginpajak.com/"
          target="_blank"
          rel="noopener"
        >
          .
        </a>
      </Container>
    </div>
  );
};

export default Footer;
