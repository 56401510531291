import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"

import {instance as API} from '../AxiosInstance'

const initialState = {
    error: null,
    status: 'idle',
    sapaan: {}
}

export const fetchInfo = createAsyncThunk('info/fetchInfo', async () => {
    const response = await API.get('/sapaan')
    return response.data
})

const infoSlice = createSlice({
    name: 'info',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchInfo.fulfilled]: (state, action) => {
            state.status = action.payload.status
            state.sapaan = action.payload.info
        },
        [fetchInfo.rejected]: (state) => {
            state.status = 'pending'
            state.session = null
        },
        [fetchInfo.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        }
    }
})

export default infoSlice.reducer