import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Link, useHistory } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
// import School from '@material-ui/icons/School';

import { instance as API } from "../../AxiosInstance";
import {
  subscribeDiskusi,
  subscribeNotifikasi,
  subscribeReply,
} from "../../ws/socket";
import Avatar from "@material-ui/core/Avatar";

import { ClickAwayListener, Grid } from "@material-ui/core";

import { useSelector } from "react-redux";
import COLOR from "../../Style";

const mediaUrl = process.env.REACT_APP_MEDIAURL;

const Profil = () => {
  const classes = useStyles();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const sessionStatus = useSelector((state) => state.session.status);
  const userInfo = useSelector((state) => state.session.session);

  useEffect(() => {
    if (sessionStatus === "success") {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [sessionStatus]);

  const profilName = (
    // <Hidden xsDown>
    <div className={classes.profilName}>
      <div className={classes.identitas}>
        {userInfo ? userInfo.namaLengkap : "-"}
      </div>
      <div className={classes.identitas}>
        {userInfo ? userInfo.kelompok : "-"}
      </div>
    </div>
    // </Hidden>
  );
  const profilAva = (
    <div className={classes.profilAva}>
      <Ava />
    </div>
  );

  const loginDaftar = <LoginDaftar />;
  return (
    <Grid container className={classes.container}>
      {isLoggedIn ? profilName : null}
      {isLoggedIn ? profilAva : loginDaftar}
    </Grid>
  );
};

function Ava() {
  const classes = useStyles();
  const [dropDown, setDropDown] = useState(false);
  const user = useSelector((state) => state.session.session);
  // const gender = useSelector( state => state.session.session.jenisKelamin)
  const [notifCount, setNotifCount] = useState(0);

  const handleClick = (e) => {
    setDropDown((prevDropDown) => !prevDropDown);
  };

  const handleClickAway = (e) => {
    e.stopPropagation();
    setDropDown(false);
  };

  function fetchNotifCount() {
    API.get("/notifikasi/get-unread-count")
      .then((response) => {
        setNotifCount(response.data);
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    subscribeReply(() => {
      fetchNotifCount();
    });
    subscribeDiskusi(() => {
      fetchNotifCount();
    });
    subscribeNotifikasi(() => {
      fetchNotifCount();
    });

    API.get("/notifikasi/get-unread-count")
      .then((response) => {
        setNotifCount(response.data);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.ava} onClick={handleClick}>
        {user.role === "pengajar" ? (
          <div className={classes.sparkling}></div>
        ) : null}

        <Avatar className={classes.avatar} src={mediaUrl + user.avatar} />
        {/* { (gender === 'l') ? <img alt="user" src={avaMale}/> : <img alt="user" src={avaFemale}/>} */}
        <SubMenuProfil
          show={dropDown}
          close={handleClickAway}
          notifCount={notifCount}
        />
        <div className={classes.notifCount}>
          {" "}
          {notifCount !== 0 ? notifCount : null}{" "}
        </div>
      </div>
    </ClickAwayListener>
  );
}

function LoginDaftar() {
  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.linklogin}>
      <Link to="/login">
        <button className={classes.button}>Login</button>
      </Link>
      <Link to="/pendaftaran">
        <button className={classes.button}>Buat Akun</button>
      </Link>
    </Grid>
  );
}

function SubMenuProfil({ show, close, notifCount }) {
  const classes = useStyles();
  const user = useSelector((state) => state.session.session);
  const [orderCount, setOrderCount] = useState(0);

  const logout = () => {
    // localStorage.clear();
    window.sessionStorage.clear();
    API.get("/auth/logout")
      .then((res) => {
        window.location.href = "/login";
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchOrderCount = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BASEURL + "/order/order-count",
          {
            signal,
            credentials: "include",
          }
        );
        const responseData = await response.text();
        setOrderCount(responseData);
      } catch (e) {
        console.log(e);
      }
    };

    fetchOrderCount();

    return () => controller.abort();
  }, []);

  const renderedSubMenuProfil = (
    <div className={classes.containerSubMenu}>
      <Grid container justifyContent="space-between">
        <ItemSubmenuProfil
          to="/profile"
          text="Profil"
          icon={<AccountCircleIcon style={{ marginRight: "5px" }} />}
        />
        {user.role === "admin" ? (
          <ItemSubmenuProfil
            to="/management"
            text="Management"
            icon={<SettingsIcon style={{ marginRight: "5px" }} />}
          />
        ) : null}
        {!["Admin", "Pengajar"].includes(user.role) && orderCount > 0 ? ( // hanya tampilkan menu transaksi ke siswa/user
          <ItemSubmenuProfil
            to="/transaksi"
            text="Transaksi"
            icon={<ReceiptIcon style={{ marginRight: "5px" }} />}
          />
        ) : null}
        {/*{user.role === "pengajar" || user.role === "admin" ? (*/}
        {/*    <ItemSubmenuProfil*/}
        {/*        to="/pengajar"*/}
        {/*        text="Pengajar"*/}
        {/*        icon={<School style={{marginRight: "5px"}}/>}*/}
        {/*    />*/}
        {/*) : null}*/}
        <ItemSubmenuProfil
          to="/notifikasi"
          text={"Notifikasi (" + notifCount + ")"}
          icon={<NotificationsActiveIcon style={{ marginRight: "5px" }} />}
        />
        <Grid item xs={12} className={classes.subMenuItem} onClick={logout}>
          <ExitToAppIcon style={{ marginRight: "5px" }} />
          Logout
        </Grid>
      </Grid>
    </div>
  );
  return <>{show ? renderedSubMenuProfil : ""}</>;
}

function ItemSubmenuProfil({ to, icon, text }) {
  const classes = useStyles();
  const history = useHistory();

  const go = (e) => {
    history.push({ pathname: to });
  };

  return (
    <Grid item xs={12} className={classes.subMenuItem} onClick={go}>
      {icon}
      {text}
    </Grid>
  );
}

const useStyles = makeStyles({
  container: {
    // border: '1px solid',
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 20,
    "@media (min-width:430px)": {
      justifyContent: "flex-end",
      flexDirection: "row",
      width: 300,
      marginTop: 0,
    },
    alignItems: "center",
  },
  profilName: {
    textAlign: "right",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingRight: 5,
    height: "100%",
    color: "#fff",
    "& div:nth-child(1)": {
      fontWeight: 900,
      marginBottom: 3,
    },
    "@media (min-width:426px)": {
      alignItems: "flex-end",
    },
  },
  profilAva: {
    textAlign: "right",
    height: "100%",
    cursor: "pointer",
    // border:'1px solid',
  },
  ava: {
    height: "100%",
    padding: "5px 0",
    position: "relative",
  },
  linklogin: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (min-width:425px)": {
      justifyContent: "flex-end",
    },
    "& a": {
      textDecoration: "none",
    },
    "& a:hover": {
      textDecoration: "underline",
    },
    "& button": {
      backgroundColor: "#fff",
      padding: "5px 10px 5px 10px",
      borderRadius: 5,
      width: 90,
      cursor: "pointer",
      marginRight: 10,
      "@media (min-width:420px)": {},
    },
  },
  containerSubMenu: {
    position: "absolute",
    width: "170px",
    top: "52px",
    right: "0px",
    display: "block",
    backgroundColor: "#fff",
    boxShadow: "1px 5px 5px 3px #eaeaea",
    zIndex: 99,
    padding: "5px",
    textAlign: "left",
    borderRadius: "5px",
    color: COLOR.hitam,
    "& a": {
      color: COLOR.coklatFooter,
      textDecoration: "none",
    },
    "& a:hover": {
      textDecoration: "none",
      color: COLOR.grey2,
    },
    "@media (max-width:425px)": {
      right: -65,
    },
  },
  subMenuItem: {
    display: "flex",
    marginBottom: "10px",
    "&:hover": {
      cursor: "pointer",
      color: COLOR.coklatFooter,
    },
    alignItems: "center",
  },
  notifCount: {
    position: "absolute",
    top: 3,
    right: -8,
    color: COLOR.coklatMuda,
    fontWeight: 700,
  },
  avatar: {
    padding: 3,
    border: "1px solid",
  },
  sparkling: {
    width: 40,
    height: 40,
    // backgroundColor: COLOR.kuning,
    position: "absolute",
    borderRadius: "50%",
    opacity: 0.8,
    animation: `$changecolor 1s linear infinite`,
  },
  "@keyframes changecolor": {
    "0%": {
      // backgroundColor: COLOR.kuning,
      border: "20px solid " + COLOR.kuning,
    },
    "25%": {
      // backgroundColor: COLOR.hijau,
      border: "10px solid " + COLOR.merah,
    },
    "50%": {
      // backgroundColor: COLOR.hijau,
      border: "0px solid " + COLOR.hijau,
    },
    "75%": {
      // backgroundColor: COLOR.hijau,
      border: "10px solid " + COLOR.kuning,
    },
    "100%": {
      // backgroundColor: COLOR.merah,
      border: "20px solid " + COLOR.hijau,
    },
  },
});

export default Profil;
