import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"

import {instance as API} from '../AxiosInstance'

const initialState = {
    error: null,
    status: 'idle',
    session: {}
}

export const fetchSession = createAsyncThunk('session/fetchsessions', async () => {
    const response = await API.get('/auth/session')
    return response.data
})

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchSession.fulfilled]: (state, action) => {
            state.status = action.payload.status
            state.session = action.payload.session
        },
        [fetchSession.rejected]: (state) => {
            state.status = 'pending'
            state.session = null
        },
        [fetchSession.rejected]: (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        }
    }
})

export const selectSessionStatus = state => state.session.status
export const selectIsUserActive = state => state.session.session.isActive

export const isSessionSuccess = state => {
    if(state.session.status === 'success'){
        return true
    }else{
        return false
    }
}


export default sessionSlice.reducer