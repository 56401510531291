import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import COLOR from "../../Style";

const useStyles = makeStyles({
  container: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: COLOR.kuning,
    position: "relative",
  },
  loader: {
    border: "5px solid " + COLOR.coklatMuda,
    borderTop: "5px solid " + COLOR.kuning,
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    animation: `$spin 1s linear infinite`,
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  mascot: {
    backgroundImage: "url('/images/mascot/maskot-4-40px.png')",
    backgroundSize: "40px 40px",
    width: 40,
    height: 40,
    position: "absolute",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
  },
});

export default function LoadingFull() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span className={classes.mascot}></span>
      <span className={classes.loader}></span>
    </div>
  );
}
