import React from 'react';
import {makeStyles} from '@material-ui/styles';
import COLOR from './../Style';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        minHeight: document.documentElement.clientHeight - 474,
        "@media (max-width:425px)": {
            minHeight: document.documentElement.clientHeight - 590,
            flexDirection: 'column-reverse',
        },
        alignItems: 'center',
    },
    bold: {
        fontSize: 36,
        fontWeight: 900,
        color: COLOR.coklatTua,
        '@media (max-width:425px)': {
            textAlign: 'center'
        }

    },
    text: {
        color: COLOR.coklatTua,
        fontSize: 26,
        '@media (max-width:425px)': {
            textAlign: 'center'
        }
    }
})

export default function ComingSoon() {
    const classes = useStyles()

    return <div className={classes.container}>
        <div className={classes.textContainer}>
            <div className={classes.bold}>SOMETHING BIG</div>
            <div className={classes.text}>IS COMING SOON</div>
        </div>
        <div className={classes.image}>
            <img src="/images/coming_soon.png"/>
        </div>
    </div>
}