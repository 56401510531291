import React from "react";
import {useHistory} from "react-router-dom";

export default function useReactPath() {
  const [path, setPath] = React.useState(window.location.pathname);
  const history = useHistory();

  React.useEffect(() => {
    const unlisten = history.listen((location, action) => {
      setPath(location.pathname);
    });
    return () => {
      unlisten();
    };
  }, [history]);
  return path;
}
