import React from "react";
import { makeStyles } from "@material-ui/styles";
import NavBar from "./NavBar";

import { Container } from "@material-ui/core";

import COLOR from "../../Style";
// import Logo from './Logo'
import Profil from "./Profil";
// import { Link } from 'react-router-dom';

const Header = function Header() {
  const classes = useStyles();
  return (
    <div className={classes.container} id="header">
      <Container maxWidth="lg" className={classes.content}>
        <NavBar />
        <div className={classes.profil}>
          <Profil />
        </div>
        <div className={classes.profil_mobile}>
          <Profil />
        </div>
      </Container>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: COLOR.darkBrown,
    marginBottom: 100,
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    height: 70,
    "@media (max-width:430px)": {
      flexDirection: "column",
      height: 140,
      justifyContent: "flex-start",
    },
  },
  profil: {
    display: "none",
    "@media (min-width:430px)": {
      display: "block",
    },
  },
  profil_mobile: {
    "@media (min-width:430px)": {
      display: "none",
    },
    width: "60%",
    height: 50,
    marginTop: 15,
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
  },
});

export default Header;
