import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import store from "./StateManagement/store";
import TestContextProvider from "./StateManagement/TestContext";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { fetchSession } from "./StateManagement/sessionSlice";
import { fetchInfo } from "./StateManagement/infoSlice";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

//-----------GOOGLE ANALYTICS--------------------//
ReactGA.initialize("UA-161739128-1");
const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

console.log("current baseUrl: " + process.env.REACT_APP_BASEURL);

(async function run() {
  store.dispatch(await fetchSession());
  store.dispatch(await fetchInfo());
})();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <TestContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </TestContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
