// const COLOR = {
//     merah: "#FF0404",
//     hijau: '#24BC00',
//     kuning: '#ffb606',
//     hitam: '#414141',
//     grey1: '#f2f2f2',
//     grey2: '#BAC5C6',
//     grey3: '#aaaaaa'
// }

const COLOR = {
  kuning: "#ECB950",
  coklatMuda: "#CDC8C7",
  coklatTua: "#504020",
  coklatFooter: "#3F3F3F",
  hijau: "#63A35F",
  merah: "#CD6B78",
  darkBrown: "#553F19",
  grey1: "#E6E7E8",
  abuTua: "#909091",
};

export const effect = {
  boxShadow: "1px 5px 10px 5px #eaeaea",
};

export default COLOR;
