import React, {createContext, useEffect, useState} from "react";

const defaultValue = {
  chapterTestCompleted: [],
  testsDetail: [],
};

export const TestContext = createContext(defaultValue);

export default function TestContextProvider({children}) {
  const [chapterTestCompleted, setChapterTestCompleted] = useState([]);
  const [testsDetail, setTestsDetail] = useState([]);

  function getPretestDone() {
    const url = process.env.REACT_APP_BASEURL + "/bank-soal/chapter-test-completed";
    const controller = new AbortController();
    const signal = controller.signal;
    const config = {
      credentials: "include",
      method: "GET",
      signal,
    };

    fetch(url, config)
        .then((response) => response.json())
        .then((data) => {
          setChapterTestCompleted(data);
        })
        .catch(e => {
          console.log(e);
        });

    return {controller};
  }

  function getTestsDetail() {
    const url = process.env.REACT_APP_BASEURL + "/bank-soal/get-tests-detail";
    const controller = new AbortController();
    const signal = controller.signal;
    const config = {
      credentials: "include",
      method: "GET",
      signal,
    };

    fetch(url, config)
        .then((response) => response.json())
        .then((data) => {
          setTestsDetail(data);
        })
        .catch(e => {
          console.log(e);
        })

    return {controller};
  }

  useEffect(() => {
    const controllerGetPretestDone = getPretestDone().controller;
    const controllerGetTestsDetail = getTestsDetail().controller;

    return () => {
      controllerGetPretestDone.abort();
      controllerGetTestsDetail.abort();
    };
  }, []);

  return (
      <TestContext.Provider
          value={{chapterTestCompleted, testsDetail, getPretestDone}}
      >
        {children}
      </TestContext.Provider>
  );
}
