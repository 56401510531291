import io from "socket.io-client";

// Notes: This is a dummy socket.io client.
// It is not connected to any server. Due to problem on the server, we cannot use socket.io for now.

// const socket = io(process.env.REACT_APP_WSS);
const socket = {
  emit: () => {},
  on: () => {},
};

function auth(userId) {
  // socket.emit('auth', userId)
}

function subscribeDiskusi(cb) {
  socket.on("tambah-diskusi", (data) => {
    // cb(data)
  });
}

function subscribeReply(cb) {
  socket.on("tambah-reply", (data) => {
    // cb(data)
  });
}

function subscribeNotifikasi(cb) {
  socket.on("mark-read", (data) => {
    // cb(data)
  });
}

export { auth, subscribeDiskusi, subscribeReply, subscribeNotifikasi };
